import { replacePattern } from '~/utils/helper/string';

export default function useShopwareNotifications() {
    const { pushError } = useNotifications();
    const { getLabels, getLabelByPath } = useCommonLabels();
    const cmsErrorMessages = getLabels('BWF/shop/errors');
    const genericErrorMessage = getLabelByPath('BWF/shared/errorText');

    const pushShopwareError = async error => {
        processShopwareErrors(error)?.forEach(message => {
            pushError(message);
        });
    };
    const processShopwareErrors = error => {
        const errorMessageArray = [];
        error.messages?.forEach(message => {
            // remove prefix from error code to match Common Label naming
            const messageKey = message.code.replace('VIOLATION::', '');

            // look if the error is known to the cms
            const translatedMessage = cmsErrorMessages[messageKey];
            if (translatedMessage) {
                const placeholder = {};
                // build CL placeholder from message meta data
                Object.entries(message.meta?.parameters ?? {}).forEach(([key, value]) => {
                    // remove sw delimiter from key
                    const cleanKey = key.replace('{{ ', '').replace(' }}', '').trim();
                    placeholder[cleanKey] = value;
                });
                errorMessageArray.push(replacePattern(translatedMessage, placeholder));
                // display translated error with replaced pattern values
            } else {
                // output original message or generic error message
                errorMessageArray.push(message?.detail ?? genericErrorMessage);
            }
        });
        return errorMessageArray;
    };

    const pushShopwareCartErrors = async errors => {
        Object.values(errors).forEach(error => {
            const { messageKey, message } = error;
            const translatedMessage = cmsErrorMessages[messageKey];
            if (translatedMessage) {
                pushError(replacePattern(translatedMessage, error));
            } else {
                pushError(`${message}`);
            }
        });
    };

    return { pushShopwareError, processShopwareErrors, pushShopwareCartErrors };
}
