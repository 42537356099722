<template>
    <div ref="productHero" class="product-hero">
        <div v-if="isEditMode()" class="editmode-info-box break-all">MAIN Product ID: {{ sku }}</div>

        <h3 class="relative font-medium text-dark-blue hyphens-auto text-5xl text-center md:text-7xl xl:text-8xl">
            {{ productName }}
        </h3>
        <div class="-mt-4 md:-mt-6 xl:-mt-10">
            <main class="relative gap-y-6 grid grid-cols-12">
                <template v-if="product">
                    <div class="col-span-full md:col-span-8 md:col-start-3 xl:col-span-6">
                        <ParallaxMove
                            :reverse-animation="false"
                            class="h-full w-full relative aspect-square overflow-visible"
                        >
                            <Transition name="hero-image-slider">
                                <div :key="activeHeroImageIndex" class="opacity-100">
                                    <ProductImage
                                        :product-media="heroMediaList[activeHeroImageIndex]?.heroSrc"
                                        logo-style="primary"
                                        main-class="inset-0 object-contain w-full h-full"
                                        fallback-class="inset-0 h-full w-full object-scale-down"
                                        loading="lazy"
                                    >
                                    </ProductImage>
                                </div>
                            </Transition>
                        </ParallaxMove>
                    </div>

                    <div
                        class="mx-auto justify-self-end col-span-full md:mx-0 md:col-span-2 md:my-auto md:w-min xl:col-span-3"
                    >
                        <ProductVariantConfigurator
                            class="p-3.5 bg-light-grey rounded-full"
                            :colors-only="true"
                            @change="onChange"
                            @before-change="productLoading = true"
                        />
                    </div>

                    <aside
                        class="aside mt-2 self-center col-span-full md:col-span-10 md:mt-6 xl:flex xl:flex-col xl:sticky xl:mt-0 xl:top-0 xl:order-first xl:col-span-3"
                    >
                        <div v-if="productClaim" class="text-lg xl:text-2xl" v-html="productClaim"></div>
                        <ProductHeroPrice
                            :class="{ invisible: productLoading }"
                            :product="activeProduct"
                        ></ProductHeroPrice>

                        <ProductHeroLink :pdp-url="pdpUrl" :product="activeProduct"></ProductHeroLink>
                    </aside>
                </template>
                <div class="mt-6 col-span-full md:mt-10 xl:mt-16 xl:col-span-8 xl:col-end-13">
                    <EditableArea :content="main" :custom-view="ProductHeroArea" />
                </div>
            </main>
        </div>
    </div>
</template>

<script setup>
import { useShopwareContext } from '@shopware-pwa/composables-next';
import { isEditMode } from '~/utils/content/magnolia';
import { EditableArea } from '@magnolia/vue-editor';
import { getProductName, getSrcSetForMedia, getTranslatedProperty } from '@shopware-pwa/helpers-next';
import ProductHeroArea from '~/templates/components/cms/ProductHeroArea.vue';
import { computed } from 'vue';
import { findHeroMedium } from '~/utils/helper/shop/media';
import { getProductEndpoint } from '@shopware-pwa/api-client';
import ParallaxMove from '~/templates/elements/ParallaxMove.vue';

const props = defineProps(['sku', 'main', 'metadata']);
const productLoading = ref(false);

const { apiInstance } = useShopwareContext();
import ProductHeroPrice from '~/templates/components/cms/ProductHeroPrice.vue';
import ProductHeroLink from '~/templates/components/cms/ProductHeroLink.vue';
import ProductImage from '~/templates/elements/ProductImage.vue';
import { PRODUCT_CARD_ASSOCIATIONS } from '~/utils/helper/shop/product';

const { data: heroProductVariants } = await useAsyncData('product-hero-variants:' + props?.sku, async () => {
    const { data } = await apiInstance.invoke.post(getProductEndpoint(), {
        filter: [
            {
                type: 'multi',
                operator: 'or',
                queries: [
                    {
                        type: 'equals',
                        field: 'parentId',
                        value: props?.sku,
                    },
                    {
                        type: 'equals',
                        field: 'id',
                        value: props?.sku,
                    },
                ],
            },
        ],
        includes: {},
        associations: {
            ...PRODUCT_CARD_ASSOCIATIONS.criteria?.associations,
            seoUrls: {},
            media: {},
            categories: {},
            cover: {},
        },
    });

    if (data.elements.length > 1) {
        return data.elements.filter(el => {
            // filter main variants from list as it makes no sense to render and it does not contain a configurator extension
            return !!el.parentId;
        });
    }
    return data.elements;
});

const uniqueColorSet = new Set();
// filter variants to have something the slider can render on
const heroColorVariants = heroProductVariants.value?.filter?.(variant => {
    const colorOption = variant.options.find(option => option.group.displayType === 'color');
    const deepKeyValue = colorOption?.colorHexCode;
    if (!uniqueColorSet.has(deepKeyValue)) {
        uniqueColorSet.add(deepKeyValue);
        return true;
    }
    return false;
});

/**
 * determine hero media list
 */
const heroMediaList = heroColorVariants?.map(variant => {
    const heroMedia = findHeroMedium(variant);
    const heroSrc = heroMedia?.media;
    const heroSrcSet = getSrcSetForMedia(heroMedia?.media);
    const heroAlt = heroMedia?.media?.alt;
    const heroTitle = heroMedia?.media?.title;
    return { heroSrc, heroSrcSet, heroAlt, heroTitle };
});

/**
 * This is still needed to init the use Product which is not happy with the partial data loaded above as the configuration groups are missing
 *
 */
const initialFullProductFromVariants = computed(() => {
    return (
        heroProductVariants.value?.find?.(variant => {
            //  && !!variant?.parentId
            return variant.id === props.sku;
        }) || heroProductVariants.value?.[0]
    );
});

const { product, changeVariant, changeConfigurator } = initialFullProductFromVariants?.value
    ? useProduct(initialFullProductFromVariants.value, initialFullProductFromVariants.value?.extensions?.groups)
    : {};
/**
 *
 * init custom data pointer
 */

const activeProduct = computed(() => {
    return heroProductVariants?.value?.find(variant => variant?.id === product?.value?.id);
});

const activeHeroImageIndex = computed(() => {
    const activeHexCode = activeProduct.value?.options?.find(
        option => option.group.displayType === 'color'
    )?.colorHexCode;
    const activeIndex = [...uniqueColorSet].indexOf(activeHexCode);
    return activeIndex > 0 ? activeIndex : 0;
});

const onChange = async changedProduct => {
    let nextColorVariant = heroColorVariants.find(variant => variant.id === changedProduct.id);
    // set new product
    changeVariant(nextColorVariant);
    // set new configurator
    changeConfigurator(product.value?.extensions?.groups);
    pdpUrl.value = await getPdpUrlByProduct(product.value);
    productLoading.value = false;
};

const { getPdpUrlByProduct } = useMagnoliaShopPages();
const pdpUrl = ref('');
pdpUrl.value = await getPdpUrlByProduct(product?.value);
const productName = computed(() => getProductName({ product: activeProduct?.value }));
const productClaim = computed(() =>
    //@ts-ignore
    getTranslatedProperty(activeProduct?.value?.translated?.customFields, 'brita_product_claim')
);

const productHero = ref(null);
useItemListTracking({
    metadata: props.metadata,
    // @ts-ignore
    items: heroProductVariants,
    rootElement: productHero,
});
</script>

<style>
.hero-image-slider-enter-active {
    transition: all 0.6s ease-out 0.6s;
}

.hero-image-slider-leave-active {
    transition: all 0.6s ease-in;
    position: absolute;
}

.hero-image-slider-enter-from {
    transform: translateX(100px);
    scale: 0.95;
    opacity: 0;
}
.hero-image-slider-leave-to {
    transform: translateX(-100px);
    opacity: 0;
    scale: 0.95;
}

.product-hero {
    .switch-btn {
        @apply h-9 w-9 border-2;
        .switch-btn-inner {
            @apply h-7 w-7 border-gray-500;
        }
    }

    .switch-container {
        @apply gap-3.5 md:gap-4;
    }
    .aside {
        @apply top-[var(--computed-header-height)];
    }
}
</style>
