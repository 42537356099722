<template>
    <div>
        <template v-if="hasEShopSet">
            <div v-if="hasBestPrice" class="flex gap-2 items-end">
                <SharedPrice :value="eShopSet?.eshopSetPriceTotal" class="font-medium text-base text-signal-red" />
                <SharedPrice
                    :value="eShopSet?.bestEshopPriceFromHistory"
                    class="font-normal text-base text-medium-grey line-through"
                />
            </div>
            <SharedPrice
                v-else
                :value="eShopSet?.eshopSetPriceTotal || price?.totalPrice"
                class="font-medium text-base text-left"
            />
        </template>
        <template v-else>
            <div class="flex items-center gap-3 flex-wrap xl:flex-nowrap">
                <SharedPrice
                    v-if="unitPrice"
                    class="font-medium text-base text-left"
                    :class="{
                        'text-signal-red': isListPrice,
                    }"
                    :value="unitPrice"
                />
                <SharedPrice
                    v-if="isListPrice"
                    class="font-normal text-base leading-snug line-through"
                    :value="price?.listPrice?.price"
                />
            </div>
            <div v-if="isListPrice" class="flex gap-1 font-normal text-sm leading-tight">
                <CommonLabel path="BWF/shop/productDetailPage/priceSaving" />
                <SharedPrice v-if="isListPrice" :value="price?.listPrice?.discount" />
                ({{ Math.abs(price?.listPrice?.percentage ?? 0) + '%' }})
            </div>
            <CommonLabel
                v-if="tauschbox"
                class="font-normal text-sm text-medium-grey"
                path="BWF/shop/exchangeBox/priceCardExchangeBoxHint"
                :placeholder="{ amountCylinders }"
            ></CommonLabel>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';
import { useProductPrice } from '@shopware-pwa/composables-next';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useExchangeBox } from '~/composables/shop/useExchangeBoxProduct';

const { product } = useProduct();
const { eShopSet, hasEShopSet, hasBestPrice } = await useProductEShopSetConfigurator();

const { price, unitPrice, isListPrice } = useProductPrice(product);
const { tauschbox, amountCylinders } = await useExchangeBox();
</script>
