<template>
    <header
        ref="header"
        class="w-full top-0 z-40"
        :class="{
            'is-sticky': isSticky,
            'is-fixed': fixed && !isEditMode,
            'is-hidden': isStickyHidden,
            'is-animated': isStickyAnimated,
            'is-submenu-open': isSubmenuOpen,
            'needs-scroll-fix': needsScrollFix,
        }"
        @mouseleave="onMouseleave()"
    >
        <div v-if="hasPromoBanner" ref="promoBanner">
            <PromoBanner />
        </div>
        <div
            class="header-wrapper relative mx-auto w-full"
            :class="{
                'is-mobile': isMobileMode,
                'is-desktop': !isMobileMode,
                'is-mobile-open': isMobileMode && isMobileOpen,
            }"
            :data-mode="isMobileMode ? 'mobile' : 'desktop'"
        >
            <div
                ref="headerBg"
                class="header-bg absolute inset-0 md:inset-2 xl:inset-4 md:rounded-lg bg-white shadow-3xl transition-all"
                :class="{ 'inset-0': isMobileOpen }"
                @transitionend="onResizeComplete()"
            ></div>
            <div class="py-2 md:py-4 xl:py-8">
                <div class="header-container relative container">
                    <div class="sr-only focus-within:not-sr-only">
                        <a ref="skipLink" href="#main" class="text-link">
                            <CommonLabel path="BWF/shared/skipToMainContent"></CommonLabel>
                        </a>
                    </div>
                    <div class="header-content flex items-center">
                        <NuxtLink
                            :to="marketSetup?.startPage ?? ''"
                            class="flex-shrink-0 flex-none"
                            @click="trackClickLogo"
                        >
                            <img
                                v-if="containsImage(pageHeader?.logo)"
                                :src="imagePath(pageHeader?.logo)"
                                :height="getImageHeight(pageHeader?.logo) ?? 96"
                                :width="getImageWidth(pageHeader?.logo) ?? 20"
                                class="flex-shrink-0 flex-none w-auto h-5 xl:h-6 z-10"
                                :title="pageHeader?.logoTitle ?? pageHeader?.logo?.metadata?.title"
                                :alt="pageHeader?.logoAlt ?? pageHeader?.logo?.metadata?.description"
                            />
                            <img
                                v-else
                                width="96"
                                height="20"
                                src="~/assets/img/logo/logo-primary.svg"
                                class="flex-shrink-0 flex-none h-5 xl:h-6 z-10"
                                title="BRITA Logo"
                                alt="BRITA Logo in blauer Schrift mit neun Kreisen auf der rechten Seite, die einen Farbverlauf von dunkel zu hellblau zeigen."
                            />
                        </NuxtLink>

                        <MainNavigation ref="main" :header="pageHeader" @submenu-toggle="onSubMenu"></MainNavigation>

                        <div class="flex-none flex items-center ml-auto z-10 gap-1 sm:gap-2">
                            <ShopButton
                                v-if="marketSetupAdmin?.shopEntrancePage"
                                aria-label="shop"
                                class="hidden md:flex flex-shrink-0"
                            ></ShopButton>

                            <CartButton
                                v-if="marketSetupAdmin?.cartPage"
                                aria-label="shopping cart"
                                @cart-count-change="onCartCountChange()"
                            ></CartButton>

                            <ProfileButton
                                v-if="marketSetupAdmin?.myBritaOverviewPage"
                                aria-label="profile"
                            ></ProfileButton>

                            <button
                                v-if="isMobileMode"
                                ref="mobileBtn"
                                aria-label="menu button"
                                class="btn btn-icon"
                                @click="toggleMobileMenu"
                            >
                                <div class="svg-icon">
                                    <SvgIcon
                                        name="Burger"
                                        class="absolute transition-all"
                                        :class="{ 'scale-y-0': isMobileOpen, 'opacity-0': isMobileOpen }"
                                    ></SvgIcon>
                                    <SvgIcon
                                        name="icon-menu-close"
                                        class="absolute transition-all"
                                        :class="{ 'scale-y-0': !isMobileOpen, 'opacity-0': !isMobileOpen }"
                                    ></SvgIcon>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import CartButton from './header/CartButton.vue';
import ShopButton from './header/ShopButton.vue';
import ProfileButton from '~/templates/partials/header/ProfileButton.vue';
import { containsImage, imagePath, getImageHeight, getImageWidth, mapNodes } from '~/utils/helper/magnolia';
import MainNavigation from './navigation/MainNavigation';
import SvgIcon from '../elements/SvgIcon';
import { ref } from 'vue';
import { inject } from 'vue';
import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import PromoBanner from '~/templates/partials/PromoBanner.vue';
import useNavigationTracking, { NAVIGATION_HEADER } from '~/composables/tracking/useNavigationTracking';
import useMainNavigationState from '~/composables/useMainNavigationState';

const { isMobileMode, isMobileOpen } = useMainNavigationState();
const content = inject('content', {});
const pageHeader = inject('pageHeader', {});
const pagePromoBanner = inject('pagePromoBanner', {});
const { marketSetup, marketSetupAdmin } = useMarketSetup();
const { trackClickLogo } = useNavigationTracking(NAVIGATION_HEADER);

const isEditMode = evaluateEditMode();
const fixed = isEditMode ? false : content.value?.fixedHeader ?? false;
const hasPromoBanner = mapNodes(pagePromoBanner?.value?.columns).length > 0;
const promoBannerSticky = pagePromoBanner?.value?.sticky ?? false;

const emit = defineEmits(['submenu-open', 'submenu-close']);

/*
 * refs
 * */
const subMenuHeight = ref('0px');
const lastScrollTop = ref(0);
const scrollWhenOpening = ref(0);
const fixedScrollPosition = ref('0px');
const revealThreshold = ref(0);
const needsScrollFix = ref(false);
const headerBounds = ref(null);
const isSubmenuOpen = ref(false);
const isSticky = ref(false);
const isStickyHidden = ref(false);
const isStickyAnimated = ref(false);
// dom refs
const main = ref(null);
const header = ref(null);
const promoBanner = ref(null);
const skipLink = ref(null);
const headerBg = ref(null);

onMounted(() => {
    if (isEditMode) {
        // TODO CHANGE BY SETTING
        return false;
    }

    headerBounds.value = header.value?.getBoundingClientRect();
    lastScrollTop.value = globalThis.scrollY ?? 0;
    const resizeObserver = new ResizeObserver(([first]) => {
        headerBounds.value = first.contentRect;
        if (fixed) {
            globalThis?.document?.documentElement.style?.setProperty(
                '--fixed-header-height',
                `${headerBounds.value?.height ?? 0}px`
            );
        }
        globalThis?.document?.documentElement.style?.setProperty(
            '--computed-header-height',
            `${headerBounds.value?.height ?? 0}px`
        );
        if (!promoBannerSticky && hasPromoBanner) {
            header.value?.style?.setProperty('--promo-banner-offset', `-${promoBanner.value?.offsetHeight ?? 0}px`);
        }
        // trigger mobile evaluation
        main.value?.evaluateMobile();
    });
    if (header.value) {
        resizeObserver.observe(header.value);
    }

    document.addEventListener('scroll', () => hasScrolled(), { passive: true });

    if (globalThis.scrollY > headerBounds.value?.bottom) {
        //
        reveal();
    }
});

/*
 * METHODS
 * */
const hasScrolled = () => {
    if (isMobileMode.value && isMobileOpen.value) {
        return;
    }

    const scrollY = globalThis.scrollY;
    const scrollUp = scrollY < lastScrollTop.value;
    const change = scrollY - lastScrollTop.value;
    const delta = Math.abs(change);

    if (isSubmenuOpen.value && needsScrollFix.value) {
        // is open and higher than viewport
        const delta = Math.min(scrollWhenOpening.value - scrollY, 0);
        fixedScrollPosition.value = `${delta}px`;
    }

    if (!scrollUp && scrollY > headerBounds.value.bottom) {
        hide();
    } else if (scrollUp && scrollY > headerBounds.value.bottom && delta > revealThreshold.value) {
        reveal();
    } else if (scrollY <= headerBounds.value.top) {
        reset();
    }
    // scroll for next tick
    lastScrollTop.value = scrollY;
};

const hide = () => {
    if (main.value?.activeMenu || skipLink.value === document.activeElement) {
        // do not hide when menu is open
        return;
    }

    isStickyHidden.value = isSticky.value = true;
    globalThis?.document?.documentElement.style?.setProperty('--sticky-header-height', `0px`);
};

const reveal = () => {
    isStickyAnimated.value = isSticky.value = true;
    isStickyHidden.value = false;
    globalThis?.document?.documentElement.style?.setProperty(
        '--sticky-header-height',
        `var(--computed-header-height, 0px)`
    );
};

const reset = () => {
    isStickyHidden.value = isSticky.value = isStickyAnimated.value = false;
    globalThis?.document?.documentElement.style?.setProperty('--sticky-header-height', `0px`);
};
const onSubMenu = () => {
    const menu = main.value?.activeMenu ?? false;
    isSubmenuOpen.value = !!menu;
    scrollWhenOpening.value = globalThis?.scrollY ?? 0;
    subMenuHeight.value = isSubmenuOpen.value ? `${menu?.$el?.offsetHeight}px` : '0px';

    if (isSubmenuOpen.value) {
        emit('submenu-open');
    } else {
        emit('submenu-close');
    }
};
const toggleMobileMenu = () => {
    isMobileOpen.value = !isMobileOpen.value;
};
const onMouseleave = () => {
    main.value?.close();
};
const onResizeComplete = () => {
    needsScrollFix.value = !isMobileMode.value && headerBg.value?.offsetHeight > window.innerHeight;
    if (needsScrollFix.value) {
        fixedScrollPosition.value = '0px';
    }
};
const onCartCountChange = () => {
    reveal();
};

/**
 * watcher
 */

watch(subMenuHeight, newSubMenuHeight => {
    header.value?.style?.setProperty('--sub-menu-height', newSubMenuHeight);
});
watch(fixedScrollPosition, newScrollPosition => {
    header.value?.style.setProperty('--fixed-scroll-position', newScrollPosition);
});
</script>

<style scoped>
.header-wrapper {
    .header-content {
        min-height: 2.75rem;
    }
    &.is-desktop {
        .header-content {
            min-height: 3rem;
        }
    }
}

& {
    --promo-banner-offset: 0px;
    --sub-menu-height: 0px;
    --fixed-scroll-position: 0px;
}

.is-sticky {
    @apply sticky top-0 transform;
    top: var(--promo-banner-offset, 0px);

    &.is-animated {
        @apply duration-300;
    }

    &.is-hidden {
        @apply transform -translate-y-full;
    }
}

.is-fixed {
    @apply md:duration-300;
    @apply md:fixed;
}

.header-bg {
    @apply transition-all duration-500;
}

.header-wrapper {
    @apply transition-all;
}

.needs-scroll-fix :deep(.header-wrapper) {
    @apply transition-none;
    transform: translateY(var(--fixed-scroll-position));
}

.header-wrapper.is-mobile-open {
    .header-bg {
        @apply h-screen inset-0 rounded-none;
    }
}

.is-desktop :deep(.header-bg) {
    --bg-inset: theme('spacing.4');
    @screen md {
        --bg-inset: theme('spacing.8');
    }
    height: calc((100% - var(--bg-inset)) + var(--sub-menu-height));
}
</style>
